import React from "react";
import PropTypes from "prop-types";
import { Element } from "rc-scroll-anim";
import websiteBanner from "../assets/imgs/website-bg1.png";
import downloadImg from "../assets/imgs/website-download.png";
import loanImg from "../assets/imgs/website-loan.png";
import andriod from "../assets/imgs/andriod.png";

import AMLC from "../assets/imgs/AMLC.png";
import NPC from "../assets/imgs/NPC.png";
import SEC from "../assets/imgs/SEC.png";
import PIC from "../assets/imgs/PIC.png";

import {
  canGoMarket,
  // canOpenURL,
  // getDeviceType,
  // isSchemeSupported,
  reportDownload,
} from "../utils/tools";
// import apple from '../assets/imgs/apple.png';
// import newIcon from '../assets/imgs/newIcon.png';
import UserAgentConponent from "../components/UserAgentConponent";

class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  };
  static defaultProps = {
    className: "banner",
  };
  constructor(props) {
    super(props);
    this.state = {
      andro: "",
      ios: "",
      userAgentVisible: false,
    };
  }
  componentDidMount() {
    console.log("window.location.origin: ", window.location.origin);
    // let uri = 'http://ok.codeblock.tech'
    fetch(window.location.origin + "/api/download/page/config?hash=1&k=1", {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=UTF-8" },
      mode: "no-cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.ret === 0) {
          const { data = {} } = res;
          this.setState({
            andro: data.url,
          });
        }
      });
    // fetch(window.location.origin +'/api/download/page/config?hash=1&k=2',{
    //     method:'GET',
    //   headers:{'Content-Type':'application/json;charset=UTF-8'},
    //   mode:'no-cors',
    //   cache:'default'
    // })
    // .then(res =>res.json())
    // .then((res) => {
    //   if(res && res.ret === 0) {
    //     const { data={} } = res;
    //     this.setState({
    //       ios: data.url
    //     })
    //   }
    // })
  }
  handleDownload = () => {
    // 使用示例
    // if (isSchemeSupported("appmarket")) {
    //   alert("华为能够被处理");
    //   console.log("URL能够被处理");
    //   // window.open("oppomarket://details?packagename=tech.codeblock.okpeso");
    //   this.setState({ userAgentVisible: true });
    //   return;
    // } else if (isSchemeSupported("oppomarket")) {
    //   alert("oppo能够被处理");
    //   console.log("URL能够被处理");
    //   // window.open("oppomarket://details?packagename=tech.codeblock.okpeso");
    //   this.setState({ userAgentVisible: true });
    //   return;
    // } else {
    //   console.log("URL不能被处理");
    //   alert("URL不能被处理");

    // }
    setTimeout(() => {
      // reportDownload();
      let deviceType = canGoMarket();
      if (deviceType) {
        this.setState({ userAgentVisible: true });
      } else {
        reportDownload();
        //旧逻辑
        this.setState({ successModal: true, testModal: false });
        // window.location.href = downLoanUrl
        window.open(this.state.andro);
      }
    }, 1000);
  };

  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { andro, userAgentVisible } = this.state;
    // // const appUrl = 'https://okpeso.onelink.me/KfYS/ADist';
    // const appUrl = '1212121';
    // console.log('----appUrl----', appUrl)
    let UserAgentConponentProps = {
      downLoanApkUrl: andro,
    };
    return (
      <Element
        id="home"
        component="section"
        className={`${className}-wrapper page`}
        onChange={navToShadow}
      >
        <div className={className}>
          <img
            src={websiteBanner}
            className={`${className}-bg-img`}
            alt="banner"
          />
          <div className={`${className}-bg-word-wrapper`}>
            <div className={`${className}-word-main`}>
              <div className={`${className}-word-left`}>
                <p className={`${className}-word-left-title`}>
                  {"Help you to get a loan easier!"}
                </p>
                <p className={`${className}-word-left-subtitle`}>
                  {"4 simple steps, get your money in 2 hours to 24 hours."}
                </p>
                <p className={`${className}-word-left-desc`}>
                  {"Register • submit information • apply • withdrawal"}
                </p>
                <div className={isMobile ? 'picBox-mobile' : 'picBox'}>
                <img
                      className={`PIC`}
                      src={PIC}
                      alt="img"
                    />
                    <img
                      className={`SEC`}
                      src={SEC}
                      alt="img"
                    />
                    <img
                      className={`NPC`}
                      src={NPC}
                      alt="img"
                    />
                    <img
                      className={`AMLC`}
                      src={AMLC}
                      alt="img"
                    />
                     
                </div>
                <div className={`${className}-word-box`}>
                  <div
                    // target="_blank"
                    rel="noopener noreferrer"
                    // href={andro}
                    key="a"
                    className={`${className}-word-download`}
                    onClick={() => this.handleDownload()}
                  >
                    <img
                      className={`${className}-ims`}
                      src={downloadImg}
                      alt="img"
                    />
                    <div>
                      <img
                        className={`${className}-andriod`}
                        src={andriod}
                        alt="img"
                      />
                      {"OKPeso for Android"}
                      <p className={`${className}-word-downTip`}>
                        ( OKApp, OKPS ){" "}
                      </p>
                    </div>
                  </div>
                  {/* <a target="_blank" rel="noopener noreferrer" href={ios} key="a" className={`${className}-word-downloads`}>
                  <img className={`${className}-ims`} src={downloadImg} alt="img" />
                  <div>
                  <img className={`${className}-andriod`}  src={apple} alt="img" />
                    {'OKPeso for iOS '}
                  </div>
                  <img className={`${className}-newIcon`} src={newIcon} alt="img"/>
                </a> */}
                </div>
              </div>
              <div className={`${className}-word-img-wrapper`}>
                <img src={loanImg} alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className={`${className}-md-img-wrapper`}>
          <img src={loanImg} alt="img" />
        </div>
        {userAgentVisible && (
          <UserAgentConponent {...UserAgentConponentProps} />
        )}
      </Element>
    );
  }
}

export default Banner;
